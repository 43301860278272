import FooterCopyrightAndLanguage from '../footer-components/footer-copyright-and-language'
import PlentyIcon from '../footer-components/plenty-icon'
import PolicyLinks from '../footer-components/policy-links'
import QuickHelpLinks from '../footer-components/quick-help-links'
import SocialMediaIconBundle from '../footer-components/social-media-icon-bundle'

interface MobileNavFooterProps {
	smallVersion?: boolean
}

const MobileNavFooter = ({ smallVersion }: MobileNavFooterProps) => {
	return (
		<div className="flex flex-grow flex-col justify-end gap-4 px-4 pb-4 pt-1">
			{!smallVersion && (
				<div>
					<PlentyIcon />
					<div className="flex flex-col gap-2">
						<SocialMediaIconBundle />
						<QuickHelpLinks />
					</div>
				</div>
			)}
			<div className="flex flex-col gap-4">
				<PlentyIcon />
				<FooterCopyrightAndLanguage />
				<PolicyLinks />
			</div>
		</div>
	)
}

export default MobileNavFooter
